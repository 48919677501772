import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTiles from "../../components/globalTiles"
import HomeSlider from "../../components/homeSlider"

const IndexPage = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/">
      <SEO title="Strona główna" />

      <section className="home-top">
        <div className="home-top__wrapper global__padd">
          <h2>
            UNITY <br /> <span>Centre</span>
          </h2>
          <h2>Krakowski Kompleks biznesowy</h2>
        </div>

        <div className="global__padd">
          <HomeSlider lang={lang} />
        </div>

        <div className="home-top__desc global__padd">
          <div className="row">
            <div className="col-md-6">
              <h1>
                UNITY CENTRE - największy, wielofunkcyjny kompleks budynków w
                Krakowie
              </h1>
            </div>
            <div className="col-md-5 offset-md-1">
              <div className="home-top__inner">
                <p>
                  Historia UNITY CENTRE rozpoczyna się w 1968 roku. Wieżowiec,
                  który jest jego częścią do dziś stanowi jedną z najbardziej
                  rozpoznawalnych budowli w Krakowie.
                </p>
              </div>
            </div>
          </div>
          <span className="global__line"></span>
        </div>
      </section>

      <GlobalTiles lang={lang} />

      <section className="home-info global__padd">
        <div className="home-info__wrapper">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <div className="home-info__inner home-info__inner--left">
                <h2>
                  50 000{" "}
                  <span>
                    m<sup>2</sup>
                  </span>
                </h2>
                <h4>powierzchni na wynajem</h4>
                <div className="home-info__ico">
                  <img
                    src={require("../../assets/images/home-info-ico-1.svg")}
                    alt=""
                  />
                  <div>
                    <strong>26</strong>
                    <p>kondygnacji naziemnych (UNITY Tower)</p>
                  </div>
                </div>
                <div className="home-info__ico">
                  <img
                    src={require("../../assets/images/home-info-ico-2.svg")}
                    alt=""
                  />
                  <div>
                    <strong>24</strong>
                    <p>szybkobieżne windy w 4 budynkach biurowych</p>
                  </div>
                </div>
                <div className="home-info__ico">
                  <img
                    src={require("../../assets/images/home-info-ico-3.svg")}
                    alt=""
                  />
                  <div>
                    <strong>400</strong>
                    <p>
                      miejsc parkingowych na dwóch podziemnych kondygnacjach
                    </p>
                  </div>
                </div>
                <div className="home-info__ico">
                  <img
                    src={require("../../assets/images/home-info-ico-4.svg")}
                    alt=""
                  />
                  <div>
                    <strong>200</strong>
                    <p>miejsc postojowych dla rowerów</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="home-info__inner home-info__inner--right">
                <h3>
                  UNITY CENTRE - największy, wielofunkcyjny kompleks budynków w
                  Krakowie
                </h3>
                <p>
                  UNITY CENTRE to nowoczesny kompleks o powierzchni 50 000 m² w
                  samym sercu Krakowa. W jego skład wchodzą 27-kondygnacyjny
                  wieżowiec biurowy klasy „AA”, dwa niższe biurowce,
                  czterogwiazdkowy hotel oraz luksusowy apartamentowiec.
                </p>
                <Link to="/pl/o-nas/" className="btn-custom btn-custom--arrow">
                  Czytaj więcej
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
